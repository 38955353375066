import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Title } from '@awdis/components'

import Layout, { LayoutData } from '../../components/Layout'
import Seo from '../../components/Seo'
import BasicLink from '../../components/BasicLink'
import HubspotContactForm from '../../components/HubspotContactForm'

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
`

const PageDescription = styled.p`
  margin-top: 32px;
  margin-bottom: 32px;
`

const PageFooter = styled.p`
  margin-top: 64px;
  margin-bottom: 48px;
`

type ContactIndividualProps = {
  pageContext: {
    layoutData: LayoutData
    page: {
      title: string
      url: string
    }
  }
}

const defaultProps = {}

const ContactIndividual = function ContactIndividual({
  pageContext: {
    layoutData,
    layoutData: { brandDescription },
    page,
  },
}: ContactIndividualProps) {
  const [submittedForm, setSubmittedForm] = React.useState(false)

  return (
    <Layout data={layoutData}>
      <Seo
        title={page.title}
        description={brandDescription}
        type="website"
        pathname={page.url}
      />
      <Title as="h2" size="small" marginBottom="32px">
        I am an individual looking for an item
      </Title>

      <PageDescription>
        <p>
          Thank you for your interest.
          {!submittedForm
            ? ` Please fill out the form below for
          guidance on how to buy our products.`
            : null}
        </p>

        {/* <p>
          You can also contact our Business Development Team on{' '}
          <BasicLink href={`tel:${contactNumber.tel}`}>{contactNumber.readable}</BasicLink>.
        </p> */}
      </PageDescription>

      <HubspotContactForm
        contactFormType="individual_user"
        onSubmit={() => {
          setSubmittedForm(true)
        }}
      />

      <PageFooter>
        <p>
          The answers to our most common questions such as, "Where can I buy
          your products from?" & "How can I get another garment with a specific
          print?" Can be found here on our{' '}
          <StyledLink to="/faqs">FAQs page</StyledLink>
        </p>
      </PageFooter>
    </Layout>
  )
}

ContactIndividual.defaultProps = defaultProps

export default ContactIndividual
